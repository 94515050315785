.contactUs-Sec {
  margin-bottom: -50px;
  padding-bottom: 150px;
}
.contact-us-main-sec {
  position: relative;
  padding: 70px 0px;
  background-color: #f5f5f5;
}

.contact-section {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  min-height: 667px;
  border-radius: 10px;
  box-shadow: 0 0 11px 1px #6666662e;
  overflow: hidden;
  background-color: #ffffff;

  &::before{
    content: "";
    position: absolute;
    width: 240px;
    height: 130px;
    background-image: url(../../../assets/images/sendImgIcon.png);
    background-position: center center;
    background-repeat: no-repeat;
    right: 19%;
    bottom: -30px;
  }

  .contact-info {
    position: relative;
    flex: 1 1;
    min-width: 300px;
    padding: 40px;
    background-color: #D4212F;
    color: #fff;
    border-radius: 10px;
    margin: 10px;
    overflow: hidden;

    // &::before {
    //   position: absolute;
    //   content: "";
    //   width: 260px;
    //   height: 260px;
    //   background: white;
    //   opacity: 0.2;
    //   border-radius: 100%;
    //   bottom: -100px;
    //   right: -80px;
    // }
    // &::after {
    //   position: absolute;
    //   content: "";
    //   width: 130px;
    //   height: 130px;
    //   background: white;
    //   opacity: 0.2;
    //   border-radius: 100%;
    //   bottom: 80px;
    //   right: 60px;
    // }

    h2 {
      font-size: 26px;
      color: white;
      font-family: 'Poppins-SemiBold';
      margin-bottom: 0;
    }

    p {
      margin: 0px 0 100px 0;
      font-size: 18px;
      color: #C9C9C9;
    }

    ul {
      list-style: none;
      padding: 0;

      li {
        display: flex;
        align-items: flex-start;
        margin: 10px 0;
        font-size: 18px;
        font-family: 'Poppins-Regular';
        gap: 20px;
        margin-bottom: 40px;

        i {
          margin-right: 20px;
          display: inline-block;
          font-size: 24px;
        }

        a {
          color: white;
          text-decoration: none;
          transition: 0.4s;

          &:hover {
            color: #000000;
          }
        }
      }
    }

    .social-icons {
      display: flex;
      margin-top: 20px;
      position: absolute;
      bottom: 40px;
      left: 40px;

      a {
        color: #fff;
        font-size: 20px;
        text-decoration: none;
        width: 41px;
        height: 41px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #000000;
        border-radius: 50px;
        margin-right: 30px;
        transition: 0.2s;

        &:last-child {
          margin: 0;
        }

        i {
          font-size: 18px;
          transition: 0.2s;
        }

        &:hover {
          background-color: white;
          color: #000000;
        }
      }
    }
  }

  .contact-form {
    position: relative;
    flex: 2;
    min-width: 300px;
    padding: 40px;
    border: 0;
    border-radius: 10px;

    .form-group {
      display: block;
      margin: 0px 0px 40px 0;

      input,
      textarea {
        flex: 1 1;
        min-width: 200px;
        margin: 0px;
        padding: 0px;
        border: 1px solid #8D8D8D;
        border-top: 0;
        border-right: 0;
        border-left: 0;
        font-size: 15px;
        font-family: 'Poppins-Medium';
        min-height: unset;
      }

      textarea {
        min-height: 150px;
        // border: 1px solid !important;
      }

      label {
        flex: 1 1;
        min-width: 200px;
        margin: 0px;
        display: flex;
        align-items: center;
        color: #8D8D8D;
        font-size: 14px;
        font-family: 'Poppins-Medium';
        margin-bottom: 0;
      }

      .radio-group {
        display: flex;
        flex-wrap: wrap;

        label {
          flex: 1;
          min-width: 100px;
          margin: 5px;
          display: flex;
          align-items: center;
        }
      }
    }

    // button {
    //   background-color: #0069d9;
    //   color: #fff;
    //   padding: 10px 20px;
    //   border: none;
    //   cursor: pointer;

    //   &:hover {
    //     background-color: #0056b3;
    //   }
    // }
  }

  .afterSubmission {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}


.map-sec{
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 90%, 70% 100%, 0 90%);
  margin-bottom: -70px;
  z-index: 0;
}

.btn.primary.hovered {
  background-color: black;
  &:hover{
    background-color: #D4212F;
  }
}

@media (max-width: 768px) {
  .contact-section {
    flex-direction: column;
  }
}