/* ================= Navbar ================= */
$primary-color: #D4212F;
$white-color: #ffffff;
$black-color: #000000;
$transition-time: 0.3s;

nav.navbar {
  position: absolute;
  width: 100%;
  height: 100px;
  top: 0;
  right: 0;
  left: 0;
  padding: 0;
  background-color: transparent !important;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  z-index: 10;
  transition: $transition-time;
  
  > .container-fluid {
    padding: 0 100px;
  }

  .for-sticky-nav {
    display: none;
  }
  
  .for-stuck-nav {
    display: block;
  }

  button.btn.primary {
    border: 1px solid $primary-color;
    font-family: 'Poppins-Regular';
    padding: 10px 30px !important;

    &:hover {
      background-color: $white-color;
      color: $black-color;
      border: 1px solid $black-color;
      outline: none !important;
    }
  }
}

.navbar-collapse {
  transition: height $transition-time ease;
}

nav.sticky {
  position: fixed !important;
  top: 0;
  right: 0;
  left: 0;
  background-color: $white-color !important;
  border-bottom: 1px solid $primary-color;
  transition: $transition-time;

  .navbar-nav {
    .nav-link {
      &.active, &.show, &:hover, &:focus {
        color: $primary-color;
      }
    }
  }

  .for-sticky-nav {
    display: block;
  }
  
  .for-stuck-nav {
    display: none;
  }
}

.text-logo {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.2s, opacity 0.2s linear, transform 0.2s ease-in-out;
  transform: translateX(-10px);
  max-width: 100px;
}

.logo:hover + .text-logo {
  visibility: visible;
  opacity: 1;
  transform: translateX(0);
}

nav.navbar .container, nav.navbar .collapse {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-nav {
  .nav-link {
    color: $white-color;
    text-align: center;
    padding: 0 !important;
    margin: 0 12px;
    position: relative;

    .small-text {
      font-size: 15px;
      font-family: 'Poppins-SemiBold';
    }

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 4px;
      background-color: $primary-color;
      left: 50%;
      transform: translateX(-50%);
      bottom: -40px;
      animation: expandContract 3s infinite;
      display: none;
    }
  }

  .nav-link.active, .nav-link.show, .nav-link:hover, .nav-link:focus {
    color: $primary-color;
    font-weight: 800 !important;

    &::before {
      display: block;
    }
  }
}

.sticky {
  a.nav-link {
    color: $black-color;
  }
}

@keyframes expandContract {
  0% {
    width: 50%;
  }
  50% {
    width: 100%;
  }
  100% {
    width: 50%;
  }
}

/* ================= Media Queries ================= */
@media only screen and (max-width: 1200px) {
  a.navbar-brand img {
    width: 150px !important;
  }
  .navbar-nav {
    .nav-link {
      margin: 0 6px;
    }
  }
  nav.navbar {
    button.btn.primary {
      padding: 10px 10px !important;
    }
  }
}


@mixin mobile-navbar-style {
  .signup {
    display: none;
  }

  .navbar-toggler {
    width: 30px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 0px solid $white-color !important;
    border-radius: 8px;
    padding: 0px !important;
    margin-right: 10px;
    z-index: 10;

    &:focus {
      // background-color: $primary-color;
      border: 0 !important;
      outline: unset !important;
      box-shadow: none !important;
      // color: $white-color !important;
    }
    img{
      width: 100%;
      filter: invert(1) brightness(1);
    }
  }

  .sticky .navbar-toggler {

    img {
      filter: invert(0) brightness(1);
    }
  }

  div#navbarSupportedContent {
    position: fixed;
    width: 100%;
    height: calc(100vh - 100px);
    overflow-y: scroll;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $black-color;
    border: 1px solid #e1e1e1;
    padding: 50px 0px !important;

    ul.navbar-nav {
      display: block;
      width: 100%;

      .nav-link {
        border-bottom: 0px solid $white-color;
        margin: 0;
        padding: 13px 0 !important;
        
        &:hover {
          background-color: $primary-color;
          color: $white-color !important;
        }

        .small-text {
          font-size: 24px !important;
        }

        &::before {
          display: none !important;
        }
      }
    }
  }

  .collapse.navbar-collapse {
    display: none !important;
  }

  .collapse.navbar-collapse.show {
    display: flex !important;
  }
}

@media only screen and (max-width: 991px) {
  @include mobile-navbar-style;

  .sticky a.nav-link {
    color: $white-color;
  }
  a.navbar-brand img {
    width: 150px !important;
  }
}