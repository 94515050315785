.partner-sec{
    position: relative;
    background: rgb(249, 249, 249);
    background: linear-gradient(94deg, rgb(250, 249, 249) 10%, rgb(255 212 217) 50%, rgb(254, 248, 249) 100%);
    clip-path: polygon(0 0, 100% 0, 100% 92%, 80% 100%, 0 92%);
    margin-bottom: -65px;
    padding: 100px 0px 150px 0px;
    // z-index: 1;

    h2{
        margin-bottom: 50px;
    }
}

.ah-marque-row {
    display: flex;
    position: relative;
    width: 100%;
    flex-wrap: wrap;
    align-content: flex-start;
    margin-bottom: 10px;
    overflow: hidden;

    .ah-white-space {
        position: absolute;
        --spacer-size: 0px;
        width: 70px;
        max-width: 70px;
        --container-widget-width: 70px;
        --container-widget-flex-grow: 0;
        top: 0px;
        --widgets-spacing: 20px 0px;
        --swiper-theme-color: #000;
        --swiper-navigation-size: 44px;
        --swiper-pagination-bullet-size: 6px;
        --swiper-pagination-bullet-horizontal-gap: 6px;
        transition: background .3s, border .3s, border-radius .3s, box-shadow .3s, transform var(--e-transform-transition-duration, .4s);
        padding: 70px 0px 0px 0px;
        background-color: transparent;
        z-index: 1;
    }

    .ah-white-space.left {
        left: 0;
        background-image: linear-gradient(270deg, rgba(2, 1, 1, 0) 0%, #fbf3f4 100%);
    }

    .ah-white-space.right {
        right: 0;
        background-image: linear-gradient(270deg, #fbeeef 0%, rgba(255, 255, 255, 0) 100%);
    }

    .ah-content-area {
        transition: background .3s, border .3s, border-radius .3s, box-shadow .3s, transform var(--e-transform-transition-duration, .4s);
        position: relative;
        display: block;
        width: 100%;
        flex-flow: row nowrap;
        overflow: hidden;

        .ah-animation-main-marque {
            animation-duration: 25s;
            width: 100%;
            flex: 0 0 auto;
            display: grid;
            grid-auto-flow: column;
            align-items: center;

            &:hover {
                -webkit-animation-play-state: paused !important;
                animation-play-state: paused !important;
            }

            img {
                width: 140px;
                height: 70px;
                object-fit: contain;
                object-position: center;
                // margin: 0 clamp(0.875rem, 0.7308rem + 0.641vw, 1.5rem);
                margin: 0 50px;
            }
        }

        .left-to-right {
            -webkit-animation: MarqueeRight 24s linear infinite 0ms;
            animation: MarqueeRight 24s linear infinite 0ms;
        }

        .right-to-left {
            -webkit-animation: MarqueeLeft 24s linear infinite 0ms;
            animation: MarqueeLeft 24s linear infinite 0ms;
        }
    }

    @keyframes MarqueeLeft {
        0% {
            transform: translate3d(0, 0, 0);
        }

        100% {
            transform: translate3d((-100%), 0, 0);
        }
    }

    @keyframes MarqueeRight {
        0% {
            transform: translate3d((-100%), 0, 0);
        }

        100% {
            transform: translate3d(0, 0, 0);
        }
    }
}