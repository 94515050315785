/*  ================= Our Brand Section ================= */
.services-page {
  .service-area {
      display: block;
  }

  .services-sec {
    padding: 80px 0px 100px 0px;
  }
}
.services-sec {
  position: relative;
  padding: 120px 0px 200px 0px;
  text-align: center;
  
  .description p {
    color: #D1D1D1 !important;
  }

  .description {
    position: relative;
    margin-bottom: 50px;
  }

  .service-box-main {
    position: relative;
    height: 470px;
    width: 100%;
    margin-bottom: 20px;

    h3 {
      font-family: 'Poppins-SemiBold';
      position: absolute;
      left: 0px;
      top: 50%;
      transform: translateY(-50%) rotate(-180deg) !important;
      margin: 0px;
      font-size: 24px;
      color: #ffffff;
      font-weight: bold;
      width: 75px;
      height: 440px;
      background-color: #D70006;
      border-radius: 8px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      writing-mode: tb-rl;
      z-index: 1;
    }

    .service-box {
      position: relative;
      padding: 30px 30px;
      border: 1px solid #1d1d1d;
      text-align: center;
      justify-items: center;
      border-radius: 8px;
      height: 100%;
      width: calc(100% - 30px);
      overflow: hidden;
      margin-left: auto;
      margin-right: auto;
      background-size: cover;
      background-position: center;
      overflow: hidden;
      background-repeat: no-repeat;

      .icon {
        position: relative;
        margin-bottom: 10px;
        transition: 0.3s cubic-bezier(.65, .05, .36, 1);
        -webkit-transition: 0.3s cubic-bezier(.65, .05, .36, 1);
        display: none;
      }

      p {
        position: absolute;
        font-family: 'Poppins-Medium';
        top: 50%;
        transform: translateY(-50%);
        left: -100%;
        // opacity: 0;
        font-size: 15px;
        margin-bottom: 0px;
        color: #252525;
        transition: 0.3s;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 28px;
        background-color: white;
        height: 420px;
        width: calc(100% - 30px);
        padding: 50px 15px 50px 80px;
        text-align: left;
        border-radius: 8px;

        span {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 9;
          -webkit-box-orient: vertical;
          margin-bottom: 10px;
        }
      }

      .d-link {
        opacity: 100;
        visibility: visible;
        color: #D4212F;
      }

      &:hover {

        p {
          left: 0%;
          color: #252525 !important;
        }
      }
    }
  }
}
