// Variables
$color-primary: #D4212F;
$color-lightest-grey: rgba(189, 206, 224, 0.1);
$color-white: white;
$color-black: black;
$color-grey: #9d9daf; // Adjust this color as needed
$font-family: 'Saira';

// Accordion Container
.accordion-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  width: 100%;
  border: 0;
  align-items: baseline;
  background: transparent;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  align-items: stretch;
  border: 1px solid #e1e1e1;
  border-radius: 6px;
  border-top: 0;
  border-right: 0;
  padding: 10px 10px;
}

.active{    
  .accordion-title, .arrow-icon{
    color: #DF1119;
  }
}

.title-icon {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
  overflow: hidden;

  &:focus-visible{
    // border-color: $color-primary;
    outline-color: unset;
  }
  &:hover{
    color: #DF1119;
  }
}
.body-1.content {
  position: relative;
  margin-top: 0px;
  font-weight: 500;
  font-size: 16px;
  line-height: inherit;
  padding: 0;
  font-family: 'Poppins-Regular';
  margin-bottom: 15px;

  // &::after{
  //   content: "";
  //   position: absolute;
  //   width: 91.5%;
  //   height: 1px;
  //   background-color: $color-black;
  //   left: 50%;
  //   transform: translateX(-50%);
  //   top: 0px;
  // }
}
.body-2 {
  font-weight: 600;
  font-size: 24px;
  font-family: 'Saira';
  margin-bottom: 15px;
  transition: 0.3s;

  &:hover{
    color: #DF1119;
  }
}
// Accordion Heading
.accordion-heading {
  width: 100%;
  word-wrap: break-word;
  margin-bottom: 64px;
}

// Accordion Design
.accordion-design {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

// Accordion Divider
.accordion-divider {
  // border: 0.5px solid $color-grey;
  background-color: $color-grey;
  height: 0.07px;
  opacity: 0.4;
  width: 100%;
}

// Accordion Title Divider Container
.accordion-title-divider-container {
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  gap: 32px;
}

// Accordion Content
.accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  width: 100%;

  &.open {
    max-height: 100vh;
  }
}
.accordion-content {
  margin-bottom: 32px;
}
.content {
  height: max-content;
  color: $color-black;
}
// Arrow Icon
.arrow-icon {
  transition: transform 0.2s ease;
  

  &.open {
    transform: rotate(-180deg);
  }
}

.accordion-title {
  color: $color-black;
}


