.address-sec{
    position: relative;
    padding-top: 100px;
    background-attachment: fixed;
    background-position: top;
    // z-index: 0;

    .row{
        position: relative;
        z-index: 1;

        h2, p{
            color: white;
        }
    }
}
.address-content {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
    flex: 1;

    .ah-box {
        padding: 20px;
        margin: 0px;
    }

    .left {
        width: 570px;
        // background-color: #D4212F;
        // transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
        padding: 50px 50px 50px 50px;
        border: 0;
    }

    .right {
        width: calc(100% - 570px);
        padding: 0px;
    }

    .ourBrand-sec {
        margin-top: 40px;

        &::before{
            display: none;
        }

        .ourBrand-services {
            display: flex;
            align-items: center;
            width: 100%;
            gap: 22px;

            p, a{
                color: white;

                &:hover{
                    color: #D4212F;
                }
            }
            .address-icon {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 67px;
                height: 67px;
                background-color: #D4212F;
                border-radius: 10px;
                min-width: 67px;

                img {
                    width: 27px;
                    filter: brightness(0) invert(1);
                }
            }
            figcaption{
                h4{
                    margin-bottom: 0px;
                }
            }

            p,
            a {
                font-size: 16px;
                font-weight: 500;
                line-height: 1.4;
            }
        }
    }
}

.tabs {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;

    .tab {
        position: relative;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-weight: 600;
        text-transform: uppercase;
        padding: 10px 20px;
        min-width: 150px;
        border: 2px solid #D4212F;
        border-radius: 50px;
        overflow: hidden;
        outline: none;
        transition: 0.3s;
        background-size: cover;
        background-repeat: no-repeat;
        color: white;
        z-index: 1;

        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: #000000;
            opacity: 0.8;
            z-index: -1;
            transition: 0.3s;
        }
        &:hover{
 
            &::before {
                content: "";
                opacity: 0.9;
            }
        }
    }
    .tab.active {
        background-color: #D4212F;
        color: white;

        &::before {
            content: "";
            opacity: 0;
        }
    }
}