.twoSideFullWidthSec {
    position: relative;
    align-self: flex-start;
    position: sticky;
    // top: 80px;
    // border-style: solid;
    // border-width: 1px 0px 1px 0px;
    // border-color: #1d1d1d;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    padding: 100px 0px;

    .inner-box {
        position: relative;
        width: 100%;
        // min-height: 822px;
        display: flex;
        align-items: center;
        justify-content: center;
        // padding: 120px 120px 120px 120px;
    }

    .left-inner-box {
        transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;

        img{
            position: relative;
        }
    }
    .mission-vission-sec {
        position: absolute;
        left: 100px;
        bottom: -30px;
        width: 295px;
        height: 322px;
        background-color: #D4212F;
        padding: 40px;
        transition: 0.4s;

        &::before{
            content: " ";
            width: 100%;
            height: 100%;
            position: absolute;
            left: 20px;
            bottom: 20px;
            background-color: white;
            transition: 0.4s;
            animation: upDown 5s infinite;
            z-index: 0;
            display: none;
        }

        &:hover{
            bottom: 0;

            &::before{
                left: 0;
                bottom: 0;
            }
        }
    }

    @keyframes upDown {
        0% {
            transform: translateY(0);
        }
        50% {
            transform: translateY(-10px);
        }
        100% {
            transform: translateY(0);
        }
    }

    .row{
        align-items: center;
    }

    .cstm-column {
        position: relative;
    }
}
.banner-lable {
    position: absolute;
    right: 40px;
    top: 0;
    z-index: 1;
}

.banner-lable div.lable1 {
    width: 100px;
    height: 160px;
    background-color: white;
    
}

.lable2 {
    width: 40px;
    height: 80px;
    background-color: #D4212F;
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
}