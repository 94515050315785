.accelerateYourBusiness-sec {
    position: relative;
    padding: 180px 0 50px 0;
    min-height: 1000px;
    display: flex;
    width: 100%;
    // align-items: center;
    overflow-x: hidden;

    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        inset: 0;
        z-index: 2;
        background-color: transparent;
        background-image: linear-gradient(270deg, #000000 50%, #000000 100%);
        opacity: 0.30;
        mix-blend-mode: multiply;
        transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
    }

    .description {
        position: relative;
        z-index: 3;
        color: white;

        .cstm-badge,
        h1,
        p {
            color: white;
        }

        h1 {
            margin-bottom: 20px;
        }

        p {
            font-size: 18px;
            margin-bottom: 35px;
        }

        button {
            color: white;
            border-color: white;
        }
    }

    .background-video {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        z-index: 1;
    }

    .float-down-arrow {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 20px;
        z-index: 5;
        animation: UpDown 1s infinite;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        i {
            font-size: 1.5vw;
            color: #D4212F;
        }

        img {
            width: 27px;
            // height: 37px;
        }
    }
}
@keyframes UpDown {
    0% {
        bottom: 30px;
    }
    50% {
        bottom: 10px;
    }
    100% {
        bottom: 30px;
    }
}

.contact-info-banner {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    list-style: none;
    z-index: 5;

    li {
        position: relative;
        transition: 0.4s;
        list-style: none;

        a {
            position: relative;
            background-color: white;
            right: -256px;
            font-size: 16px;
            display: inline-block;
            border-radius: 5px;
            margin-bottom: 10px;
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
            text-decoration: none;
            color: red;
            transition: 0.3s;

            i {
                position: relative;
                font-size: 18px;
                color: red;
                padding: 13px;
                display: inline-block;
                border-right: 1px solid red;
                border-radius: 5px;
                border-bottom-right-radius: 0;
                border-top-right-radius: 0;
                transition: 0.3s;
            }

            span {
                display: inline-block;
                margin-left: 5px;
                // padding: 8px;
                // transition: 0.3s;
            }
        }

        &:hover {
            // margin-right: 0px;
            a {
                right: -0px;
                i {
                    background-color: red;
                    color: white;
                    border-color: white;
                }
            }
        }
    }
}