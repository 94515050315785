.opportunity-sec {
    position: relative; padding: 100px 0px 80px 0px;
    .card {position: relative; border: 0px; padding: 0; text-align: center; background-color: transparent; gap: 15px;
        img{width: 300px; height: 255px; object-fit: contain; margin: 0 auto; gap: 15px;}
        .card-body { position: relative;
            
            p{margin: 15px 0;}
        }
    }
}


// For Company Business
// Your Name, Company Name, Your Roll in the Company, Contact.

// For Freshers
// Your Name, Education, Contact Number.

// For Business Ideas
// Your Name, Education, Contact Number.



