.sticky-box {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
}

.company-history {
    position: relative;
    padding: 150px 0px 40px 0px;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center;
    color: white;
    margin-top: -65px;

    &::before {
        content: "";
        width: 100%;
        height: 100%;
        background: rgb(0, 0, 0);
        background: linear-gradient(0deg, #161616 0%, rgba(22, 22, 22, 0.9882352941) 70%, rgba(22, 22, 22, 0.73) 90%);
        inset: 0;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 0;
    }


    .row {
        position: relative;

        p {
            color: #ffffffdb;
            font-size: 15px;
        }
    }

    .company-history-slider {
        position: relative;

        &::before {
            content: "";
            position: absolute;
            width: 95%;
            height: 1px;
            background-color: white;
            top: 150px;
            left: 50%;
            transform: translateX(-50%);
        }

        .slick-slide{
            text-decoration: none;
            outline: none;
            border: 0;

            &:hover{
                cursor: grab;
            }
        }

        .history-slide {
            position: relative;
            text-align: center;
            padding-top: 50px;
            padding-bottom: 50px;

            h3 {
                position: relative;
                color: transparent;
                font-size: 55px;
                font-weight: 800;
                // line-height: 1em;
                -webkit-transition: all 500ms ease;
                -moz-transition: all 500ms ease;
                -ms-transition: all 500ms ease;
                -o-transition: all 500ms ease;
                transition: all 500ms ease;
                -webkit-text-stroke-width: 1px;
                -webkit-text-stroke-color: rgb(212 33 47);
                margin-bottom: 10px;
            }

            span.for-round {
                position: relative;
                display: block;
                width: 5px;
                height: 15px;
                margin: 0 auto 30px auto;
                border: 1px solid #ffffff;
                border-radius: 0px;
                background-color: white;
            }

            p {
                position: relative;
                font-size: 17px;
                font-family: 'Poppins-SemiBold';
                width: 260px;
                min-height: 90px;
                margin: 0 auto;
                margin-bottom: 18px;
            }
        }
        .slick-dots li{
            display: none;
        }

        .slick-prev, .slick-next{
            top: 150px;
            font-size: 0;
            width: 0;
            height: 0;
            z-index: 1;

            &::before{
                content: "";
                position: absolute;
                top: -15px;
                font-size: 30px;
                color: #D4212F;
                display: inline-block;
                width: 31px;
                height: 31px;
                background-size: 46%;
                background-position: center center;
                filter: brightness(0) invert(1);
                background-repeat: no-repeat;
                border: 2px solid white;
                border-radius: 50px;
                padding: 10px;
            }

            // &:hover{
            //     &::before{
            //         filter: brightness(0) invert(0);
            //     }
            // }
        }

        .slick-prev {
            left: 6px;
            &::before{
                background-image: url('../../../assets/icons/back.png');
            }
        }
        .slick-next {
            right: 40px;
            &::before{
                background-image: url('../../../assets/icons/forward.png');
            }
        }
        .slick-current {
            h3 {
                color: #D4212F;
                // text-shadow: 1px 1px 3px black, 0 0 1em #D4212F, 0 0 0.2em #D4212F;
            }

            span.for-round {
                background-color: #D4212F;
                border-color: #D4212F;
            }
        }
    }
}

.vmv-sec {
    position: relative;
    background-color: white;
    padding: 40px;
}

.aboutUs-page-sec .col-md-7 {
    padding-left: 60px;
}