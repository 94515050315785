/* SlickSlider.css */
.slide-content {
  text-align: center;
  padding: 0px;
}

.slide-content .icon {
  font-size: 40px;
  margin-bottom: 20px;
}

.slide-content h3 {
  font-size: 19px;
  margin-bottom: 10px;
  font-family: 'Saira';
  color: white;
}

.slide-content p {
  font-size: 14px;
  color: #ffffff;
  margin-bottom: 20px;
}

.about-us-sec-slider {
  position: relative;
  text-align: left;
}

.mission-vission-sec {

  .slick-dots {
    text-align: left;

    li {
      width: 20px;
      height: 6px;
      border-radius: 0;
      background-color: #ffa6a6;
      transition: 0.4s;

      button {

        &::before {
          display: none;
        }
      }
    }
  }
}
    .mission-vission-sec .slick-dots li.slick-active {
      width: 40px;
      background-color: white;
    }

    .mission-vission-sec .slick-arrow {
      display: none !important;
    }