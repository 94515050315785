.our-customer-sec {
    position: relative;
    background: rgb(249, 249, 249);
    background: linear-gradient(94deg, rgb(250, 249, 249) 10%, rgb(255 218 222) 50%, rgb(254, 248, 249) 100%);
    // clip-path: polygon(0 3%, 25% 0, 100% 5%, 100% 95%, 75% 100%, 0 97%);
    clip-path: polygon(0 3%, 25% 0, 100% 5%, 100% 95%, 69% 100%, 0 97%);
    min-height: 1150px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -65px;
    padding-top: 100px;
    padding-bottom: 100px;
    margin-bottom: -65px;
    z-index: 2;

    p{
        margin-bottom: 30px;
    }
    .right-image{
        position: relative;
        width: 457px;
        height: 782px;
        text-align: right;
        margin-left: auto;
        transition: 0.4s;

        img{
            transition: 0.4s;
        }

        &::before{
            content: " ";
            position: absolute;
            width: 25px;
            height: 80%;
            border-radius: 8px;
            background-color: #DF1119;
            left: -20px;
            top: 50%;
            transform: translateY(-50%);
            z-index: -1;
            transition: 0.4s;
        }
    }
}