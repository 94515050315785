// My Space Times Solutions
@media screen and (max-width: 1440px) {
  .custom-container {
    max-width: 100%;
    width: 90%;
  }

  .ah-vmv-sec {
    .description {
      .tab {
        min-width: 120px !important;
      }
    }
  }

  .team-member {
    padding: 10px !important;
    min-height: 292px !important;
  }
}

@media screen and (max-width: 1199px) {
  h2 {
    font-size: 32px;
  }

  .company-history .company-history-slider {
    .history-slide {
      h3 {
        font-size: 54px !important;
      }
    }

    &::before {
      width: 91% !important;
      top: 140px !important;
    }

    .slick-arrow {
      &::before {
        top: -25px !important;
      }
    }
  }

  .ah-vmv-sec .description {
    padding: 30px 30px !important;
  }

  .team-member {
    min-height: 281px !important;

    h3 {
      font-size: 16px !important;
    }

    p {
      font-size: 13px !important;
    }
  }

  .description-body {
    gap: 10px !important;

    .sidebar-details-page {
      width: 275px !important;

      nav {
        ul {
          li {
            button {
              font-size: 15px !important;
              padding: 15px 20px !important;

              i {
                right: 10px !important;
                font-size: 16px !important;
              }
            }
          }
        }
      }

      .contact {
        small {
          font-size: 18px !important;
        }

        a {
          font-size: 16px !important;
        }

        i {
          width: 60px !important;
          height: 60px !important;
          margin: 0 auto 20px auto;
          font-size: 20px !important;
        }
      }

      .pdf-download-btn {
        font-size: 18px !important;
        padding: 15px 15px !important;
      }
    }

    .main-content {
      width: calc(100% - 275px) !important;

      .main-image-box {
        height: 260px !important;

        img {
          object-fit: contain !important;
        }
      }

      .detailed-inner-sec .sub-image-box {
        height: 146px !important;
      }
    }

    h2 {
      font-size: 22px !important;
    }

    p {
      font-size: 16px !important;
      margin-bottom: 20px !important;
    }
  }

  .red-box {
    padding: 30px !important;
  }

  .careers-page.services-sec {

    .description-body {
      padding-top: 40px !important;

      .sidebar-details-page nav ul {
        height: 440px !important;
      }
    }

    .job-box {
      padding: 15px !important;

      .short-description {

        h3,
        .info {
          font-size: 18px !important;

          .title {
            font-size: 14px !important;
          }
        }
      }

      .icon {
        font-size: 16px !important;
      }
    }
  }

  .post-details-page {
    margin-bottom: 0px !important;

    div {
      font-size: 14px !important;
    }
    .non-sticky {
      grid-row-gap: 10 !important;
      padding: 0px 0px 0px 0px !important;
    }
    h3 {
      font-size: 26px !important;
    }
    h4 {
      font-size: 18px !important;
    }
    .get-in-touch {
      gap: 10px;
    }
    .queries-form {
      padding: 20px 20px !important;

      .about-job-info {

        li {
          font-size: 14px !important;

          i{
            font-size: 16px !important;
          }
        }
      }
      h4.ah-pt-50.mb-4 {
        padding-top: 30px !important;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  nav.navbar>.container-fluid {
    padding: 0 20px !important;
  }

  h1 {
    font-size: 53px;
    line-height: 50px;
  }

  h2 {
    font-size: 32px;
  }

  .services-sec {
    position: relative;
    padding: 80px 0px 130px 0px !important;

    .service-box-main {
      position: relative;
      height: 400px;

      h3 {
        font-size: 20px !important;
        width: 65px !important;
        height: 350px !important;
      }
    }
  }

  .body-2 {
    font-size: 20px;
  }

  .landing-page-wrap {
    .our-customer-sec {
      min-height: auto;
      padding: 100px 0px;

      .col-md-7 {
        padding-right: 30px;

        .body-1.content {
          text-align: justify;
        }
      }
    }

    .ourBrand-sec {
      text-align: center;

      .ourBrandMedia {
        width: 100%;
        height: auto;
        margin-bottom: 50px;

        .ourBrandMediaLeft {
          left: 0px;
        }

        .ourBrandMediaRight {
          right: 0;
        }
      }

      figcaption {
        text-align: left;
      }
    }

    .opportunity-sec {

      .card {
        // img {
        //   // width: 100%;
        //   // height: 255px;
        // }

        .card-body {
          h4 {
            min-height: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .nav-link::before {
    display: none !important;
  }

  .accelerateYourBusiness-sec {
    min-height: 850px !important;

    img {
      width: 200px;
    }
  }

  .opportunity-sec .card img {
    width: 100% !important;
  }

  .twoSideFullWidthSec {
    text-align: center;

    .row {
      justify-content: center !important;
    }
  }

  .our-customer-sec,
  .partner-sec,
  .ah-footer-new-changes .footer-section {
    clip-path: none !important;
  }

  .our-customer-sec {
    .right-image {
      display: none;
      width: 300px;
      height: auto;
      margin: 0 auto !important;

      img {
        width: 100%;
        height: auto;
        object-fit: contain;
      }
    }
  }

  .footer-section {
    padding-top: 40px !important;
  }

  .services-sec {
    position: relative;
    padding: 80px 0px 130px 0px !important;

    .service-box-main {
      position: relative;
      height: 400px !important;

      .service-box {
        width: calc(100% - 60px) !important;

        p {
          height: 300px !important;
          line-height: 25px !important;
          padding: 30px 15px 30px 50px !important;

          span {
            -webkit-line-clamp: 8 !important;
            line-clamp: 8;
          }
        }
      }

      h3 {
        font-size: 20px !important;
        width: 65px !important;
        height: 340px !important;
      }
    }
  }

  .ourBrand-sec {

    .row.justify-content-between.align-items-center {
      justify-content: center !important;
    }
  }

  .aboutUs-page-sec {
    clip-path: unset !important;
    padding: 50px 0 30px 0 !important;

    .row {

      .media {
        width: unset !important;
        left: 30px !important;

        .media-logo {
          right: 60px !important;
        }
      }
    }
  }

  .company-history {
    margin-top: 0px !important;
    padding: 50px 0px 40px 0px !important;

    .company-history-slider {
      .history-slide {

        p {
          min-height: auto !important;
        }
      }
    }
  }

  .ourTeam-sec {
    padding: 50px 0px !important;
  }

  .team-member {
    min-height: 180px !important;
    margin-bottom: 20px !important;

    h3 {
      min-height: unset;
    }
  }

  .ah-vmv-sec {
    padding: 0px 0 50px 0 !important;
    margin-bottom: 0 !important;

    img.line1 {
      width: 100%;
      margin-bottom: 50px;
    }

    .animated-badge-right {
      width: 100px !important;
      top: -34px !important;
      right: 0 !important;
    }
  }

  .description-body {

    .sidebar-details-page {
      width: 240px !important;

      nav ul li button {
        font-size: 14px !important;
        padding: 10px 10px !important;

        i {
          font-size: 14px !important;
        }
      }
    }

    .main-content {
      width: calc(100% - 240px) !important;

      h2 {
        font-size: 18px !important;
      }

      p {
        font-size: 14px !important;
      }
    }
  }

  .careers-page.services-sec {
    padding: 50px 0px 50px 0px !important;

    .description-body {
      padding-top: 0px !important;

      .sidebar-details-page nav ul {
        height: 340px !important;
      }

      // .job-box {
      //   padding: 15px !important;

      // .short-description {
      //     h3, .info {
      //       font-size: 18px !important;

      //       .title {
      //         font-size: 14px !important;
      //       }
      //     }
      //   }
      //   .icon {
      //     font-size: 16px !important;
      //   }
    }
  }

  .contact-us-main-sec {

    .contact-section {
      min-height: auto;

      .contact-info {
        min-width: 260px !important;
        padding: 20px !important;

        h2 {
          font-size: 18px !important;
        }

        p {
          font-size: 14px !important;
          margin: 0px 0 50px 0 !important;
        }

        ul li {
          gap: 10px !important;
          font-size: 14px !important;

          i {
            margin-right: 0px !important;
            font-size: 20px !important;
          }
        }

        .social-icons {
          bottom: 20px !important;

          a {
            width: 35px !important;
            height: 35px !important;

            i {
              font-size: 14px !important;
            }
          }
        }
      }

      .contact-form {
        padding: 20px !important;

        .primary {
          padding: 15px 30px !important;
        }
      }
    }
  }

  .post-details-page {
    h3 {
      font-size: 18px !important;
      margin-bottom: 0 !important;
    }
    h4 {
      font-size: 16px !important;
    }
    .left {      
      figure {
        margin-bottom: 0px !important;
      }
    }
    .right {
      padding: 0px 0px 0px 15px !important;
    }
    .queries-form {
      padding: 10px 10px !important;

      .about-job-info {
        text-align: center !important;
  
        li {
          display: block !important;
          padding-left: 0 !important;
          padding-top: 20px !important;
          font-size: 14px !important;
          margin-bottom: 15px !important;

          i{
            position: absolute !important;
            top: 0px !important;
            left: 50% !important;
            transform: translateX(-50%) !important;
          }
          
          span{
            display: block !important;
            text-align: center;
          }
        }
      }
    }
    .get-in-touch {
      display: flex !important;
      flex-direction: column;
      a {
        order: 2 !important;
      }
      ul.info-sec {
        order: 1 !important;
        margin-bottom: 10px !important;
      }
    }
    .primary {
      padding: 7px 30px !important;
    }
  }
  .jobPost-page {
      padding: 50px 0px 0px 0px !important;
  }
  .lbl-group {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}

@media screen and (max-width: 768px) {
  .subscribe-sec {
    min-height: 410px;
  }

  .copyright {
    display: block;
  }

  .copyright p {
    margin: 10px 0px 0px 0px;
  }

  ul.social-icon li a {
    margin: 0 10px !important;
  }

  footer * {
    text-align: left !important;
  }

  .copyright p,
  .copyright a {
    display: inline-block;
  }

  .copyright p {
    margin: 0px 0px 20px 14px;
  }

  .copyright ul {
    display: block;
    margin-bottom: 20px;
  }

  .copyright ul li {
    display: block;
    margin-left: 0;
  }

  ul.social-icon li:first-child a {
    margin-left: 0 !important;
  }

  // Old
  h1 {
    font-size: 40px !important;
    line-height: initial;
  }

  h2 {
    font-size: 24px !important;
    line-height: initial;
  }

  .accelerateYourBusiness-sec {
    position: relative;
    min-height: 500px !important;

    img {
      width: 150px !important;
    }

    .description p {
      font-size: 14px;
      margin-bottom: 30px;
    }

    .float-down-arrow {
      display: none !important;
    }
  }

  .opportunity-sec {
    padding: 60px 0px 40px 0px !important;

    .card .card-body h4 {
      min-height: 63px !important;
    }
  }

  .contact-info-banner {
    display: none !important;
  }

  .twoSideFullWidthSec {
    padding: 50px 0px !important;
  }

  .services-sec .service-box-main {
    h3 {
      width: 65px !important;
      height: 350px !important;
      font-size: 20px !important;
    }

    .service-box {
      width: calc(100% - 40px) !important;
    }
  }

  .landing-page-wrap .our-customer-sec {
    padding: 50px 0px !important;
  }

  .body-2 {
    font-size: 18px !important;
  }

  .body-1.content {
    font-size: 14px;
  }

  // .container {
  //   padding: 0 40px !important;
  // }
  .ourBrand-sec {
    h2 {
      font-size: 32px !important;
    }
  }

  .inner-box.left-inner-box {

    .mission-vission-sec {
      position: relative;
      left: unset;
      right: unset;
      bottom: unset;
      top: unset;
      display: block;
      width: 100%;
      height: 290px;
      padding: 20px;
    }
  }

  .primary {
    padding: 8px 15px !important;
  }

  .aboutUs-page-sec {
    .row {
      .media {
        width: unset !important;
        left: 0px !important;

        .media-logo {
          right: 0px !important;
        }
      }
    }
  }

  .company-history .company-history-slider::before {
    width: 81% !important;
    top: 140px !important;
  }

  .team-member {
    text-align: center !important;
    min-height: unset !important;

    h3 {
      font-size: 18px !important;
      min-height: unset !important;
    }

    p {
      font-size: 14px !important;
    }
  }

  .ah-vmv-sec .description .tab {
    display: block !important;
    margin-bottom: 10px !important;
  }

  .footer-section {
    .container {
      padding: 0 30px !important;
    }

    .subscribe-form button {
      height: 53px;
    }

    .copyright-text p {
      text-align: center !important;
    }

    .footer-content .col-xl-4 {
      padding-right: 0 !important;
    }
  }

  .description-body {
    display: block !important;
    margin: 50px 0px 50px 0px !important;

    .sticky {
      position: relative !important;
    }

    .sidebar-details-page {
      width: 100% !important;
      margin-bottom: 20px !important;

      nav {
        margin-bottom: 20px !important;

        ul {
          height: 250px !important;
        }
      }

      .contact {
        margin-bottom: 20px !important;
      }
    }

    .main-content {
      width: 100% !important;
    }
  }

  .job-box .icon-container {
    display: none !important;
  }

  .innerPageBanner-sec .breadcrumb {
    min-height: 50px !important;
  }

  .careers-page.services-sec {
    padding: 0px !important;
    margin-bottom: 0px !important;

    .description-body {
      margin: 30px !important;
    }
  }

  .contact-us-main-sec {
    .contact-section {
      .contact-info {
        text-align: center !important;
        padding: 20px 20px 50px 20px !important;

        p {
          margin: 0px 0 20px 0 !important;
        }

        ul li {
          display: block !important;
          align-items: center;
          margin-bottom: 20px !important;

          i {
            display: block !important;
            margin-bottom: 10px;
          }
        }

        .social-icons {
          margin-top: 0 !important;
          bottom: 20px !important;
          left: 50% !important;
          transform: translateX(-50%) !important;
        }
      }
    }

  }

  .post-details-page {
    .get-in-touch {
      text-align: center !important;
      margin-bottom: 0px !important;

      a {
        margin-bottom: 20px !important;
      }
      ul.info-sec {
        margin-bottom: 0px !important;
      }
      .ah-py-100 {
        padding-bottom: 50px !important;
      }
    }
    div {
      text-align: center !important;

      ul{
        li{
          text-align: left !important;
        }
      }
    }
  }
  .padding-70-100 {
    padding: 50px 20px 50px 20px !important;
  }
  form input {
    height: 30px !important;
  }
  .contactUs-Sec {
    margin-bottom: 0px !important;
    padding-bottom: 50px !important;
  }
  .ah-footer-new-changes .footer-section .subscribe-form input{
    height: 55px !important;
  }
  .request-modal .modal-body {
    position: relative !important;
    padding: 30px 20px 20px 20px !important;

    .modal-body form .form-group.form-check {
      padding-left: 0px !important;
      display: block !important;
    }
  }
  .css-13cymwt-control {
    min-height: 40px !important;
  }
  .innerPageBanner-sec .breadcrumb .d-link, .innerPageBanner-sec .breadcrumb .current {
    font-size: 14px !important;
  }
  .form-group.text-end {
    text-align: center !important;
  }
  .map-sec {
    clip-path: unset !important;
    margin-bottom: 0 !important;
  }
  .footer-section {
    padding-top: 0px !important;
  }
  .contact-us-main-sec {
    padding: 50px 0px !important;
  }
  .company-history .company-history-slider .history-slide {
    width: 100% !important;
    max-width: 100% !important;
  }
  .lbl-group {
    grid-template-columns: repeat(1, 1fr) !important;
  }
  .company-history .company-history-slider .history-slide p {
    width: 100% !important;
  }
  .slick-slide {
    opacity: 0;
  }
  .slick-slide.slick-current {
    opacity: 1;
  }
  .get-in-touch ul.info-sec li {
    display: block !important;
    margin: 0 !important;
    
    i {
      margin: 0px auto 10px auto;
    }
  }
  .ah-vmv-sec .description .tab{
    margin-right: 0px !important;
  }
  .for-desktop{
    display: none !important;
  }
  .for-mbl{
    display: block !important;
  }
}

@media only screen and (max-width:640px) {
  h2 {
    font-size: 32px;
    line-height: 40px;
  }
  h4 {
    font-size: 18px !important;
  }
  form button {
    font-size: 16px;
    padding: 15px 22px;
    height: 50px;
    height: auto;
  }

  form input {
    height: 55px;
    padding-right: 126px;
  }

  .subscribe-sec {
    min-height: 335px;
  }
  .paddingY-100, .ah-py-100 {
    padding-top: 50px  !important;
  }
}

@media screen and (max-width: 500px) {
  body * {
    text-align: center !important;
  }

  .navbar-toggler {
    width: 40px;
    height: 40px;
  }

  h1 {
    font-size: 22px !important;
    line-height: inherit !important;
  }

  h2 {
    font-size: 18px !important;
    line-height: inherit !important;
  }

  p,
  .cstm-badge span,
  .body-1.content {
    font-size: 14px !important;
  }

  .accelerateYourBusiness-sec {
    padding: 140px 0 0px 0 !important;
  }

  .opportunity-sec {
    padding: 40px 0px 10px 0px !important;
  }

  .twoSideFullWidthSec {
    padding: 20px 0px 10px 0px !important;
  }

  .services-sec {
    padding: 40px 0px 40px 0px !important;

    .service-box-main {
      h3 {
        left: 0px !important;
      }

      .service-box {
        width: calc(100% - 30px) !important;
        margin-right: 0px !important;
        margin-left: auto !important;
      }
    }
  }

  .our-customer-sec {
    margin-top: 0px !important;
  }

  .ourBrand-sec .ourBrand-services figcaption * {
    text-align: left !important;
  }

  .landing-page-wrap .our-customer-sec {
    padding: 50px 0px 10px 0px !important;
  }

  .container {
    padding: 0 30px !important;
  }

  .ourBrand-sec {
    h2 {
      font-size: 18px !important;
    }

    h4 {
      font-size: 18px !important;
    }
  }

  .ah-footer-new-changes .footer-content .col-xl-4 {
    padding-right: 0px !important;
  }

  .ah-footer-new-changes .footer-section .footer-widget-heading h3 {
    margin-bottom: 20px;

    &::before {
      width: 100% !important;
    }
  }

  .ah-footer-new-changes .footer-section .subscribe-form button {
    height: 53px;
  }

  .twoSideFullWidthSec .left-inner-box .mission-vission-sec {
    height: unset !important;

    img {
      margin: 0 auto !important;
    }
  }

  .slick-dots {
    bottom: -15px !important;
    
    li {
      width: 10px !important;
      height: 10px !important;
    }
  }

  .opportunity-sec {
    .card .card-body h4 {
      min-height: unset !important;
    }
  }

  .active .accordion-title,
  .active .arrow-icon {
    margin-bottom: 0 !important;
    padding-bottom: 10px !important;
  }
  .aboutUs-page-sec .row .media .media-logo {
    width: 100px !important;
  }
  .applicant-box .candidate-img{
    margin: 0 auto;
  }
}