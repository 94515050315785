.primary {
    background-color: #D4212F;
    color: #FFFFFF;
    border-radius: 0px;
    padding: 17px 50px;
    font-size: 14px;
    font-weight: bold;
    font-family: 'Poppins-SemiBold';

    &:hover {
        background-color: #000000;
        color: white;
    }
}

// .primary-outlined {}

// .secondary {}

.secondary-outlined {
    background-color: transparent;
    color: #FFFFFF;
    border: 2px;
    border-radius: 50px;
    padding: 10px 20px;
    font-size: 14px;

    &:hover {
        background-color: #D4212F;
    }

    &:active {
        background-color: #780a55 !important;
    }
}

// .link {
//     background-color: transparent;
//     color: #FFFFFF;
//     border: 2px solid transparent;
//     border-radius: 50px;
//     font-size: 14px;

//     &:hover {
//         background-color: #D4212F;
//     }

//     &:active {
//         background-color: #780a55 !important;
//     }
// }

// .link-outlined {
//     background-color: transparent;
//     color: #D4212F;
//     border: 2px solid #D4212F;
//     padding: 10px 20px;
//     font-size: 14px;

//     &:hover {
//         background-color: #D4212F;
//         color: white;
//     }

//     &:active {
//         background-color: #00706b !important;
//     }
// }