.innerPageBanner-sec {
    position: relative;
    text-align: center;
    min-height: 410px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-top: 80px;
    background-position: top center;

    &::after{
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        inset: 0;
        z-index: 0;
        background-color: transparent;
        // background-image: linear-gradient(270deg, #00c7d1 50%, #8dc5af 100%);
        opacity: 0.99;
        mix-blend-mode: multiply;
        transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
        /* background: linear-gradient(45deg, #6e55a6, #e1a8c5); */
        // background: linear-gradient(90deg, rgb(94 93 93 / 50%) 0%, rgb(43 154 235 / 50%) 30%, rgb(237 194 215) 100%);
        // background-color: rgb(32 32 32 / 60%);
        // mix-blend-mode: multiply;
    }

    .description {
        position: relative;
        z-index: 1;

        h1{
            margin-bottom: 10px;
            color: white;
            text-transform: uppercase;
            font-size: 50px;
            text-align: left;
        }
    }

    .breadcrumb{
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        text-align: center;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        gap: 8px;
        color: white;
        background-color: #ffffff33;
        min-height: 70px;
        z-index: 1;
        
        .d-link, .current{
            position: relative;
            display: inline-block;
            font-size: 17px;
            font-weight: 500;
            font-family: 'Poppins-Medium';
            text-decoration: none;
            color: #D4212F;
        }
        .d-link{
            color: white;
            display: inline-block;
            margin-right: 20px;

            &:hover{
                color: #D4212F;
            }
        }
        i{
            display: inline-block;
            margin-right: 8px;
            transition: 0.3s !important;
        }
        .breadcrumb-default-delimiter {
            position: relative;
            display: inline-block;

            &::before {
                content: "";
                background-color: white;
                display: inline-block;
                height: 14px;
                opacity: 1;
                position: relative;
                top: -1px;
                vertical-align: middle;
                width: 1px;
                -webkit-transform: rotate(20deg);
                transform: rotate(20deg);
            }
        }
    }
}

.aboutUs-page-sec{
    position: relative;
    background: #FBFBFB;
    // background: linear-gradient(94deg, rgb(250, 249, 249) 10%, rgb(255 212 217) 50%, rgb(254, 248, 249) 100%);
    clip-path: polygon(0 0, 100% 0, 100% 92%, 80% 100%, 0 92%);
    padding: 100px 0 170px 0;
    overflow: hidden;
    z-index: 1;

    .row{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .media{
            position: relative;
            width: fit-content;

            .media-logo{
                position: absolute;
                right: -60px;
                bottom: 60px;
            }
        }
    }
}