.ourTeam-sec {
  padding: 60px 40px;
  background-color: transparent;
  text-align: center;

  &::before {
    display: none;
  }

  // h2, p {
  //   color: #000000;
  // }

  .team-category {
    margin: 20px 0;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    // background: linear-gradient(94deg, rgb(250, 249, 249) 10%, rgb(255 212 217) 50%, rgb(254, 248, 249) 100%);
    // clip-path: polygon(0 0, 100% 0, 100% 92%, 80% 100%, 0 92%);
    min-height: 260px;
    transition: 0.4s;

    h3 {
      font-size: 26px;
      margin-bottom: 10px;
      color: #D4212F;
    }

    p {
      font-size: 16px;
      color: #666;
    }

    &:hover {
      transform: scale(1.05);
    }
  }
}

.team-member {
  position: relative;
  text-align: left;
  background-color: white;
  padding: 30px;
  min-height: 260px;
  box-shadow: 0px 0px 20px 20px 
    #00000008;
  transition: 0.3s;

  &::before{
    content: "";
    position: absolute;
    width: 80%;
    height: 3px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #D4212F;
    transition: 0.3s;
  }

  h3 {
    font-size: 20px;
    color: #1B1A1A;
    min-height: 60px;
    transition: 0.3s;
  }

  p {
    font-size: 15px;
    color: #000000;
    transition: 0.3s;
  }

  &:hover{
    background-color: #D4212F;
    color: white;

    &::before{
      background-color: white;
    }
  
    h3 {
      color: white;
    }
  
    p {
      color: white;
    }
  }
}

// @media (min-width: 768px) {
//   .ourTeam-sec {
//     h2 {
//       font-size: 48px;
//     }

//     .team-category {
//       margin: 20px 10px;

//       h3 {
//         font-size: 28px;
//       }

//       p {
//         font-size: 18px;
//       }
//     }
//   }
// }