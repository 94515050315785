/* ================= General ================= */
@font-face {
  font-family: 'SairaItalic';
  src: url('../assets/fonts/Saira-Italic-VariableFont_wdth\,wght.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Saira';
  src: url('../assets/fonts/Saira-VariableFont_wdth\,wght.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-Regular';
  src: url('../assets/fonts/Poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-Medium';
  src: url('../assets/fonts/Poppins/Poppins-Medium.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: url('../assets/fonts/Poppins/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-ExtraBold';
  src: url('../assets/fonts/Poppins/Poppins-ExtraBold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

html, body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  // height: 100%;
  scroll-behavior: smooth;
}
body *{
  scroll-behavior: smooth !important;
}
body {
  background: white;
  color: #000000;
  font-family: 'Poppins-Regular';
  font-optical-sizing: auto;
  font-weight: normal;
  font-style: normal;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f5f5f5;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #D4212F;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #000;
  width: 10;
}

section {
  position: relative;
  display: block;
}

// .container{
//   max-width: 1530px;
//   padding: 0 30px;
// }
// .container, .container, .container-fluid{
//   width: 100%;
//   margin: 0 auto;
//   padding: 0 15px;
// }
.custom-container {
  max-width: 1560px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

/*  =================Subscribe section  =================*/
.subscribe-sec {
  min-height: 910px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 0px 30px 30px;
  background: radial-gradient(122.71% 245.32% at 50% -145.32%, #9ACBFF 0%, #041E2D 85.46%, #041E2D 100%);
}

.subscribe-sec .row {
  justify-content: center;
  text-align: center;
}

.paddingY-100,
.ah-py-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.ah-pt-100 {
  padding-top: 100px;
}

.ah-pb-100 {
  padding-bottom: 100px;
}

.ah-pb-50 {
  padding-bottom: 50px;
}

.ah-pt-50 {
  padding-top: 50px;
}

.ah-mt-50 {
  margin-top: 50px;
}

.ah-mb-50 {
  margin-bottom: 50px;
}

.theme-h-color-white {
  color: white;

  span {
    color: #D4212F;
  }
}

h1 {
  position: relative;
  font-size: 65px;
  line-height: 65px;
  font-weight: 700;
  font-family: 'Saira'
}

h2 {
  display: inline-block;
  font-size: 42px;
  font-style: normal;
  font-weight: bold;
  line-height: inherit;
  color: #000000;
  margin-bottom: 15px;
  font-family: 'Saira'
  // span {
  //   // color: #01d3b6;
  //   color: #1d1d1d;
  //   background-color: #01d3b6;
  //   background-image: linear-gradient(146deg, #00d7cd 19%, #026460);
  //   -webkit-text-fill-color: transparent;
  //   -webkit-background-clip: text;
  //   background-clip: text;
  // }
}

h2.text-white,
h3.text-white,
h4.text-white {
  color: white;

  // span {
  //   // color: #01d3b6;
  //   color: #1d1d1d;
  //   background-color: #01d3b6;
  //   background-image: linear-gradient(146deg, #00d7cd 19%, #026460);
  //   -webkit-text-fill-color: transparent;
  //   -webkit-background-clip: text;
  //   background-clip: text;
  // }
}

h2.text-black,
h3.text-black,
h4.text-black {
  color: #000000;

  // span {
  //   // color: #01d3b6;
  //   color: #1d1d1d;
  //   background-color: #01d3b6;
  //   background-image: linear-gradient(146deg, #00d7cd 19%, #026460);
  //   -webkit-text-fill-color: transparent;
  //   -webkit-background-clip: text;
  //   background-clip: text;
  // }
}

h3 {
  display: inline-block;
  font-family: 'Saira';
  font-size: 36px;
  line-height: 1em;
  font-weight: 700;
  line-height: inherit;
  color: #000000;
  margin-bottom: 15px;

  // span {
  //   color: #01d3b6;
  //   color: #1d1d1d;
  //   background-color: #01d3b6;
  //   background-image: linear-gradient(146deg, #01e0c3 19%, #D4212F);
  //   -webkit-text-fill-color: transparent;
  //   -webkit-background-clip: text;
  //   background-clip: text;
  // }
}

h4 {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.3;
  text-transform: capitalize;
  margin-bottom: 20px;
  font-family: 'Saira';
}

.description {
  p {
    font-size: 16px;
    color: #000000;
  }
}

.cstm-badge {
  font-size: 16px;
  color: #D4212F;
  font-weight: bold;
  border: 0cap solid;
  border-radius: 0px;
  padding: 0;
  line-height: 1;
  margin-bottom: 15px;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  justify-self: center;
  gap: 5px;
  font-family: 'Saira';

  @keyframes opacityAnimation {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  img {
    width: 14px;
    height: 14px;
    // background-color: blue;
    animation: opacityAnimation 2s infinite;
  }

  span {
    // margin-left: 5px;
    font-size: 16px;
    line-height: 100%;
  }
}

.animated-badge-right {
  animation: opacityAnimation 3s infinite;
  position: absolute;
  top: -90px;
  right: -50px;
  width: 165px;
  height: auto;
}

.cstm-badge.white {
  img {
    filter: brightness(0) invert(1);
  }
}

p {
  font-size: 16px;
  color: #000000;
  font-family: 'Poppins-Regular';
}

.p-50 {
  padding: 50px;
}


.bg-theme {
  background-color: #f6d3d5;
  // background-image: linear-gradient(145deg, #D4212F 0%, #ed9da3 100%);
}

.bg-theme-light {
  background-color: #f7d7d9;
  // background-image: linear-gradient(90deg, #01a5ae 0%, #abdfcb 100%);  
}

.text-red {
  color: #df1119;
}

span.rti--tag {
  display: inline-block;
  margin: 10px 0 5px 10px;
  background-color: #ffd5d8;
}

/* Subscribe Form */
form {
  position: relative;
  display: block;

  .form-group {
    position: relative;
    margin-bottom: 8px;

    label {
      margin-top: 5px;
      font-size: 16px;
      margin-bottom: 5px;
      font-weight: 600;

      sup {
        color: #df1119;
        font-size: 14px;
      }
    }

    input,
    textarea {
      position: relative;
      border: 1px solid rgba(29, 29, 29, 0.14);
      border-radius: 0px;
      width: 100%;
      min-height: 40px;
      padding: 10px 20px;
      font-size: 16px;
      font-weight: 400;
      // text-transform: capitalize;
      color: #000000;
      margin-bottom: 0px;

      &:focus-visible {
        // outline: #D4212F auto 1px;
        outline: unset;
        border-color: #D4212F !important;
      }
    }

    textarea,
    ReactQuill {
      min-height: 220px;
      border-radius: 0px;
      resize: none;
    }

    input::placeholder,
    textarea::placeholder {
      color: #BDCEE0;
    }

    span.for-error {
      position: absolute;
      left: 0px;
      bottom: -20px;
      display: inline-block;
      color: red;
      font-size: 14px;
      line-height: 1;
      background-color: white;
      padding: 0px 3px;
    }

    button {
      // color: #FFF;
      // font-family: 'Saira';
      // font-size: 20px;
      // font-weight: 400;
      // line-height: 24px;
      // border-radius: 48px;
      // background: #21409A;
      // padding: 15px 32px;
      // border: 1px solid #21409a;
      // transition: 0.3s;
      // outline: none;
      // height: 64px;
      // position: absolute;
      // right: 0px;

      &:hover {
        background-color: #000000;
      }
    }
  }
}

.h-200 {
  height: 200px;
}

.bg-color-box {
  position: relative;
}

.bg-light-gray {
  background-color: #DCDCDC36;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.padding-70-100 {
  padding: 70px 100px 70px 100px;
}

.bg-cover {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.ah-list-items {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0;
  padding: 0;
  gap: 10px;

  .ah-icon-list-item {
    display: flex;
    font-size: inherit;

    .link-text,
    a {
      text-decoration: none;
      transition: 0.4s;
    }

    .link-text:hover {
      color: black !important;
    }
  }
}

.bg-light {
  background-color: #F5F3EF;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
}

.d-link {
  position: relative;
  font-size: 14px;
  text-decoration: none;
  color: #000000;
  font-weight: 500;
  opacity: 1;
  transition: 0.4s;

  i {
    padding-left: 1px;
    position: relative;
    left: 0px;
    transition: 0.3s;
  }

  &:hover {
    color: #01a5ae;

    i {
      left: 5px;
    }
  }
}

.btn {
  min-width: 150px;

  &:focus-visible {
    color: inherit !important;
  }
}

.btn-dark {
  background-color: #000000;
  border: 2px solid white;
  color: white;
  border-radius: 28px;
  padding: 14px 24px;
  font-size: 14px;
  transition: 0.3s;

  &:hover {
    background-color: #01a5ae;
    border: 2px solid white;
    color: #000000;
  }
}

.btn-outline {
  background-color: transparent;
  border: 2px solid white;
  color: white;
  border-radius: 28px;
  padding: 14px 24px;
  font-size: 14px;
  transition: 0.3s;

  &:hover {
    background-color: black !important;
    color: white !important;
    border-color: white;
  }
}

.ah-list-items {
  list-style-type: none;
  margin-bottom: 30px;
  padding: 0;

  .ah-list-item {
    padding-bottom: calc(10px / 2);
    display: flex;
    font-size: inherit;
    align-items: center;

    .ah-list-text {
      position: relative;
      display: block;
      font-size: 16px;

      &::before {
        position: relative;
        content: '';
        width: 6px;
        height: 6px;
        display: inline-flex;
        background: #D4212F;
        box-shadow: 0px 0px 5px 1px #D4212F;
        -webkit-box-shadow: 0px 0px 5px 1px #D4212F;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        margin-right: 17px;
        top: -2px;
      }
    }
  }
}

// .sticky {
//   // width: auto;
//   // position: sticky;
//   // top: 80px;
//   // height: 100vh;
// }

// .non-sticky {
//   // width: auto;
//   display: grid;
//   grid-template-columns: auto;
//   grid-row-gap: 40px;
// }

.p-relative {
  position: relative;
}

.quill {
  background: white;
}

.quill .ql-toolbar {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.quill .ql-container {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  min-height: 100px;
}

.error-message {
  color: red;
}

.shadeAnimation {
  position: absolute;
  right: 0;
  top: 0;
  width: fit-content;

  img {
    position: absolute;
    right: 0;
    top: 0;
  }

  .line1 {
    animation: upDown 4s infinite;
  }
}

@keyframes upDown {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-50px);
  }

  100% {
    transform: translateY(0);
  }
}

.overlay-downToUp {

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 0);
    background: linear-gradient(0deg, #161616 0%, rgb(22 22 22 / 81%) 70%, rgba(22, 22, 22, 0.73) 90%);
    inset: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
  }
}

.d-btn {
  position: relative;
  display: inline-block;
  color: white;
  background-color: #D4212F;
  min-width: 150px;
  padding: 8px 15px;
  border-radius: 0;
  transition: 0.4s;
  border: 1px solid;

  &:hover {
    color: #000000;
    background-color: white;
    border-color: #000000;
  }
}

.btn-check:checked+.btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check)+.btn:active {

  background-color: #D4212F;
  border-color: #D4212F;
}

.service-area {
  display: none;
}

.service-1,
.service-2,
.service-3 {
  display: block !important;
}

.bg {
  img {
    position: absolute;
  }

  img.bg-1 {
    right: 0;
    top: 0;
    animation: moveBackground1 5s ease-in-out infinite;
  }

  img.bg-2 {
    left: 0;
    bottom: -50px;
    // transform: rotate(180deg);
    animation: moveBackground2 5s ease-in-out infinite;
  }
}

.text-dark {
  color: black !important;
}

.contact-info,
.queries-form {
  &::before {
    position: absolute;
    content: "";
    width: 260px;
    height: 260px;
    background: white;
    opacity: 0.2;
    border-radius: 100%;
    bottom: -100px;
    right: -80px;
  }

  &::after {
    position: absolute;
    content: "";
    width: 130px;
    height: 130px;
    background: white;
    opacity: 0.2;
    border-radius: 100%;
    bottom: 80px;
    right: 60px;
  }
}

.bg-lightgray {
  background-color: #f5f5f5 !important;
}

.bg-white {
  background-color: white !important;
}

.bg-shadow {
  box-shadow: 0 0 11px 1px rgba(102, 102, 102, 0.1803921569);
}

.red-box {
  position: relative;
  width: 100%;
  background-color: #D4212F;
  border-radius: 5px;
  padding: 50px;
  color: white;
}

.slick-dots li button::before {
  color: white !important;
  opacity: .75 !important;
}
.slick-dots li.slick-active button:before{
  opacity: 1 !important;
  color: #D4212F !important;
}

.for-desktop{
  display: block !important;
}
.for-mbl{
  display: none !important;
}
.description-body.m-0 {
  margin: 0 !important;
}