// Services Detials Page
.description-body {
    position: relative;
    margin: 70px 0px 70px 0px;
    display: flex;
    gap: 40px;

    // clip-path: polygon(0 0, 100% 0, 100% 92%, 80% 100%, 0 92%);

    .sticky {
        position: -webkit-sticky;
        position: sticky;
        top: 0px;
        width: 100%;
        height: fit-content;
    }

    .non-sticky {
        width: 100%;
        display: grid;
        grid-template-columns: auto;
        grid-row-gap: 0px;
    }

    p {
        font-size: 22px;
        font-family: 'Poppins-Medium';
        color: #444444;
        margin-bottom: 30px;
    }

    // DetailsPageSidebar
    .sidebar-details-page {
        // position: relative;
        width: 485px;
        padding: 0px;
        margin: 0px;

        nav {
            position: relative;
            margin-bottom: 40px;

            ul {
                position: relative;
                list-style: none;
                padding: 0;
                margin-bottom: 15px;
                // margin-right: 5px;
                height: 630px;
                overflow-y: scroll;
                overflow-x: hidden;

                li {
                    margin-bottom: 10px;
                margin-right: 5px;

                    padding: 0;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    button {
                        position: relative;
                        font-family: 'Poppins-SemiBold';
                        font-size: 24px;
                        width: 100%;
                        border-radius: 5px;
                        background-color: #FFF5F6;
                        text-align: left;
                        padding: 28px 50px;
                        border: 0px solid;
                        transition: 0.3s;

                        // &::before{
                        //     content: "";
                        //     position: absolute;
                        //     right: 40px;
                        //     top: 50%;
                        //     transform: translateY(-50%);
                        // }
                        i {
                            position: absolute;
                            font-size: 20px;
                            transition: 0.3s;
                            right: 40px;
                            top: 50%;
                            transform: translateY(-50%);
                            transition: 0.3s;
                            color: #D4212F;
                        }

                        &:hover {
                            background-color: #D4212F;
                            color: white;

                            i {
                                color: white;
                            }
                        }
                        &:focus-visible{
                            outline: none !important;
                            border: 0px;
                        }
                    }
                    button.active {
                        background-color: #D4212F;
                        color: white;

                        i {
                            color: white;
                        }
                    }
                }

                &::-webkit-scrollbar {
                    width: 5px;
                }
            }
        }

        .contact {
            position: relative;
            font-family: 'Saira';
            color: white;
            text-align: center;
            display: flex;
            flex-direction: column;
            margin-bottom: 40px;
            transition: 0.3s;

            p {
                font-size: 50px;
                color: white;
                font-family: 'Saira';
                transition: 0.3s;
            }

            i {
                position: relative;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                align-items: center;
                width: 100px;
                height: 100px;
                margin: 0 auto 30px auto;
                font-size: 30px;
                background-color: white;
                color: #D4212F;
                transition: 0.3s;
                border-radius: 50px
            }

            small {
                font-size: 20px;
                transition: 0.3s;
            }

            a {
                text-decoration: none;
                font-size: 40px;
                color: white;
                transition: 0.3s;

                &:hover {
                    color: #D4212F;
                }
            }

            &:hover {
                background-color: #000000;
                color: white;
            }
        }

        .pdf-download-btn {
            position: relative;
            background-color: #D4212F;
            color: white;
            font-family: 'Poppins-SemiBold';
            font-size: 24px;
            border: 0px;
            padding: 28px 50px;
            width: 100%;
            border-radius: 5px;
            transition: 0.4s;

            img {
                display: inline-block;
                margin-right: 26px;
            }

            &:hover {
                background-color: #000000;
            }
        }
    }

    .main-content {
        position: relative;
        width: calc(100% - 485px);

        .main-image-box {
            position: relative;
            height: 535px;
            margin-bottom: 30px;

            img {
                position: relative;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center center;
                background-color: #575757;
                object-fit: contain;
            }
        }

        .detailed-inner-sec {
            position: relative;

            .sub-image-box {
                position: relative;
                height: 286px;
                margin-bottom: 30px;

                img {
                    position: relative;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    background-color: #575757;
                }
            }
        }
    }
}

// DetailsPageSidebar


.services-sec {
    position: relative;
    background-attachment: fixed;

    &::before {
        content: "";
        width: 100%;
        height: 100%;
        background: rgb(0, 0, 0);
        background: linear-gradient(0deg, rgb(0 0 0 / 72%) 0%, rgb(0 0 0 / 88%) 82%);
        inset: 0;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        //opacity: 0.9;
    }
}

// .services-sec {
//     position: relative;
//     background-attachment: fixed;

//     &::before {
//         content: "";
//         width: 100%;
//         height: 100%;
//         background: rgb(0, 0, 0);
//         background: linear-gradient(0deg, rgb(0 0 0 / 72%) 0%, rgb(0 0 0 / 88%) 82%);
//         inset: 0;
//         position: absolute;
//         left: 0;
//         right: 0;
//         top: 0;
//         bottom: 0;
//         // opacity: 0.9;
//     }
// }

// .for-services-details {
//     position: relative;
//     background-color: white;

//     .row {
//         display: grid;
//         grid-template-columns: 1fr 1fr;
//         grid-gap: 0px;
//         position: relative;
//         align-items: flex-start;
//         z-index: 1;

//         .inner-box {
//             padding: 50px 35px;
//         }
//     }

//     .questions-list {
//         position: relative;
//         padding-left: 40px;

//         li {
//             font-size: 20px;
//             font-weight: 500;
//             color: #D4212F;
//             margin-bottom: 20px;

//             .questions-title {
//                 font-size: 20px;
//                 font-weight: 500;
//                 color: #000000;
//                 margin-bottom: 3px;
//             }

//             .questions-description {
//                 font-size: 16px;
//                 font-weight: 400;
//                 color: #575757;
//             }

//             ul.sub-ul {
//                 li {
//                     strong {
//                         display: block;
//                         font-size: 18px;
//                         font-weight: 500;
//                         color: #000000;
//                         margin-bottom: 3px;
//                     }
//                 }
//             }
//         }
//     }
// }

// .single-detail-page {
//     .services-sec {
//         &::before {
//             display: none;
//         }
//     }

//     .for-services-details {
//         padding-top: 0px;
//     }
// }