// // /* ================= Footer ================= */

// ==================================================== New Footer ================================================= //
.ah-footer-new-changes {
  overflow: hidden;

  .footer-content {
    .row {
      display: flex;
      justify-content: space-between;
    }
    .col-xl-4 {
      padding-right: 50px;
    }
  }

  ul {
    margin: 0px;
    padding: 0px;
  }

  .footer-section {
    background: #161616;
    position: relative;
    background-position: bottom center;
    border-radius: 0px;
    padding-top: 100px;
    clip-path: polygon(0 0, 70% 10%, 100% 0, 100% 100%, 0 100%);

    .footer-cta {
      border-bottom: 1px solid #373636;

      .single-cta i {
        color: #D4212F;
        font-size: 30px;
        float: left;
        margin-top: 8px;
      }

      .cta-text {
        padding-left: 15px;
        display: inline-block;

        h4 {
          color: #fff;
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 2px;
        }

        span {
          color: #757575;
          font-size: 15px;
        }
      }
    }

    .footer-content {
      position: relative;
      z-index: 2;
    }

    .footer-pattern img {
      position: absolute;
      top: 0;
      left: 0;
      height: 330px;
      background-size: cover;
      background-position: 100% 100%;
    }

    .footer-logo {
      margin-bottom: 30px;

      img {
        max-width: 200px;
      }
    }

    .footer-text p {
      margin-bottom: 14px;
      font-weight: 500;
      font-size: 14px;
      color: #ffffff;
      line-height: 28px;
    }

    .footer-social-icon span {
      color: #F9F9F9;
      display: block;
      font-size: 15px;
      font-weight: 700;
      font-family: 'Poppins-Regular';
      margin-bottom: 10px;
    }

    ul.contact-info-footer {
      position: relative;
      padding: 0;
      margin: 0;

      li {
        position: relative;
        width: 100% !important;
        border-bottom: 1px solid #404040;
        padding-bottom: 10px;

        h4 {
          margin-bottom: 10px;
          color: #F9F9F9 !important;
          font-size: 15px;
          font-family: 'Poppins-Regular';
        }

        a {
          position: relative;
          display: inline-block;
          color: #D4212F !important;
          font-size: 15px !important;
          font-family: 'Poppins-SemiBold' !important;

          i {
            display: inline-block;
            margin-right: 10px;
            animation: zoomInOut 2s infinite;
          }

          &:hover {
            color: white !important;
          }
        }

        &:last-child{
          border-bottom: 0px;
        }
      }
    }

    footer h4 {
      font-size: 17px;
      font-family: 'Saira';
      color: white;
    }

    .footer-social-icon a {
      color: #fff;
      font-size: 16px;
      margin-right: 15px;

      i {
        height: 50px;
        width: 50px;
        text-align: center;
        font-size: 18px;
        line-height: 37px;
        border-radius: 50px;
        background-color: transparent;
        color: white;
        transition: 0.3s;
        border: 2px solid #D4212F;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        animation: zoomInOut 2s infinite;

        &:hover {
          background-color: white;
          color: #D4212F;
        }
      }
    }

    @keyframes zoomInOut {

      0%,
      100% {
        transform: scale(1);
      }

      50% {
        transform: scale(1.1);
      }
    }

    .facebook-bg {
      background: #3B5998;
    }

    .twitter-bg {
      background: #55ACEE;
    }

    .google-bg {
      background: #DD4B39;
    }

    .footer-widget-heading h3 {
      color: #fff;
      font-size: 20px;
      font-weight: 900;
      margin-bottom: 40px;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: -15px;
        height: 2px;
        width: 50px;
        background: #D4212F;
        animation: zoomInOut 2s infinite;
      }
    }

    .footer-widget ul li {
      display: block;
      width: 100%;
      margin-bottom: 12px;

      a {
        color: white;
        text-transform: capitalize;
        font-size: 14px;
        transition: 0.3s;
        text-decoration: none;
        font-family: 'Poppins-Regular';

        &:hover {
          color: #D4212F;
        }
      }
    }

    .subscribe-form {
      position: relative;
      overflow: hidden;
      min-height: 42px;
      padding: 0px;
      background-color: #222222;
      margin-top: 20px;
      margin-bottom: 20px;
      border: 0px;

      input {
        width: 100%;
        padding: 10px 15px;
        font-weight: 500;
        background: #ffffff;
        border: 1px solid #D4212F;
        border-radius: 0px;
        color: #616161;
        font-size: 14px;
        font-weight: 500;
        outline: none;
      }

      button {
        position: absolute;
        right: 0;
        background: #D4212F;
        padding: 6px 20px 7px 20px;
        border: 1px solid white;
        border-radius: 0px;
        top: 1px;
        font-size: 0;

        i {
          color: #fff;
          font-size: 26px;
          transform: rotate(-6deg);
          animation: zoomInOut 2s infinite;
        }
      }
    }

    .copyright-area {
      background: #161616;
      padding: 25px 0;
      border-top: 1px solid #404040;

      .copyright-text {
        p {
          margin: 0;
          font-size: 14px;
          font-family: 'Poppins-Regular';
          color: #ffffff;

          a {
            color: #D4212F;
            transition: 0.3s;
            text-decoration: none;

            &:hover {
              color: blue;
            }
          }
        }
      }

      .footer-menu {
        li {
          a {
            color: white;

            &:hover {
              color: #D4212F;
            }
          }
        }
      }
    }

    .footer-menu {
      li {
        display: inline-block;
        margin-left: 20px;

        a {
          font-size: 14px;
          font-family: 'Poppins-Regular';
          font-weight: 400;
          color: #000000;
          transition: 0.3s;
          text-decoration: none;

          &:hover {
            color: #D4212F;
          }
        }
      }
    }
  }
}

.footer-widget-heading {
  // padding-top: 50px;
}

.text-p{
  color: #ffffff;
}