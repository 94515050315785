.ah-vmv-sec{
    position: relative;
    clip-path: polygon(0 0, 100% 0, 100% 96%, 66% 100%, 0 96%);
    padding: 100px 0 170px 0;
    background-color: transparent;
    margin-bottom: -40px;
    z-index: 1;

    .row{
        position: relative;
        z-index: 1;
    }

    .description {
        position: relative;
        padding: 55px 65px;
        background-color: white;
        margin-bottom: 0 !important;
        box-shadow: 0 0 50px 10px #D4212F1c;
        z-index: 1;

        p{
            min-height: 120px;
        }

        .cstm-badge, p, h2 {
            margin-bottom: 30px;
        }
        h2 {
            font-size: 40px;
        }

        .tab {
            position: relative;
            display: inline-block;
            padding: 10px;
            font-size: 15px;
            font-family: 'Poppins-Medium';
            border: 2px solid #011C2A;
            margin-right: 20px;
            min-width: 180px;
            text-align: center;
            transition: 0.4s;

            &:hover{
                background-color: #D4212F;
                color: white;
                // border-color: #D4212F;
            }
        }
        .tab.active{
            background-color: #D4212F;
            color: white;
            // border-color: #D4212F;
        }
    }
}


// style.scss
 
@keyframes moveBackground1 {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-10px);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes moveBackground2 {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(0);
    }
}