/*  ================= Our Brand Section ================= */
.ourBrand-sec {
    position: relative;
    padding: 150px 0px 100px 0px;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;

    &::before {
        content: "";
        width: 100%;
        height: 100%;
        background: rgb(0,0,0);
        // background: linear-gradient(0deg, rgb(0 0 0 / 72%) 0%, rgb(0 0 0 / 88%) 82%);
        /* Gradient */
        background: linear-gradient(0deg, #161616 0%, #161616fc 70%, rgb(22 22 22 / 73%) 90%);

        // opacity: 0.9;
        inset: 0;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 0;
    }

    .row{
        position: relative;
        z-index: 1;

        h2{
            font-size: 42px;
            text-transform: capitalize;
        }
        h4{
            color: white;
        }
        p{
            color: #ffffff;
        }
    }
    .ourBrandMedia{
        position: relative;
        text-align: center;
        width: 500px;
        height: 500px;

        .ourBrandMediaMain {
            position: relative;
            width: 100%;
            height: 100%;
        }

        .ourBrandMediaLeft {
            position: absolute;
            left: -20px;
            top: 1px;
            z-index: 1;
            height: 100%;
        }

        .ourBrandMediaRight {
            right: -20px;
            position: absolute;
            z-index: 1;
            height: 100%;
        }
    }

    .ourBrand-services {
        position: relative;
        display: flex;
        width: 100%;
        align-items: flex-start;
        gap: 10px;
        margin-bottom: 20px;

        figcaption {
                padding-right: 10px;
            
            h4 {
                font-size: 19px;
                font-weight: 700;
                margin-bottom: 8px;
            }
            p{
                font-size: 14px;
                color: #ffffff;
                // text-align: justify;
            }
        }
    }
    p{
        line-height: 24px;
    }

    .row {
        .row {
            margin-top: 45px;
        }
    }
}

@media (max-width: 767px) {
    .twoSideFullWidthSec .inner-box {
      min-height: 350px !important;
      padding: 20px !important;
    }
  }