.request-modal {
    .modal-content {
        border-radius: 20px;
    }

    .modal-header {
        padding: 20px 40px 0px 40px;
        border: 0px;
    }

    .modal-body {
        position: relative;
        padding: 30px 40px 20px 40px;

        form {
            position: relative;
            z-index: 1;

            .form-group {
                position: relative;
                margin-bottom: 24px;

                label {
                    margin-top: 0px;
                    font-size: 15px;
                    margin-bottom: 8px;
                    color: #3B3D41;
                    font-family: 'Poppins-SemiBold';
                }

                .css-13cymwt-control,
                .css-t3ipsp-control {
                    font-size: 15px;
                    font-family: 'Poppins-Regular';
                    border: 1px solid hsl(0, 0%, 80%);
                    border-radius: 0px;

                    .css-19bb58m {
                        margin: 0;
                        padding: 0;
                    }
                }

                .css-1nmdiq5-menu {
                    font-size: 14px;
                }

                .css-1p3m7a8-multiValue {
                    background-color: #D4212F;
                    color: white;

                    .css-9jq23d {
                        color: white;
                    }
                }

                input,
                textarea {
                    height: 40px;
                    font-size: 15px;
                    font-family: 'Poppins-Regular';
                    border: 1px solid hsl(0, 0%, 80%);
                    box-shadow: none !important;

                    &::placeholder {
                        color: #999BA1;
                    }

                    &:focus {
                        // outline: none;
                        outline: unset;
                        border-color: transparent !important;
                        box-shadow: none;
                    }

                    &:focus-visible {
                        outline: #D4212F auto 1px;
                        // outline: unset;
                        // border-color: #D4212F !important;
                    }
                }

                textarea {
                    min-height: unset !important;
                    height: 100px !important;
                }

                span.text-danger {
                    font-size: 14px;
                }
            }

            .css-t3ipsp-control:hover {
                border-color: #D4212F !important;
            }

            .css-t3ipsp-control {
                border-color: #D4212F !important;
                box-shadow: 0 0 0 1px #D4212F !important;
            }

            .form-check {
                label {
                    margin-bottom: 0;
                }

                input {
                    position: relative;
                    width: 18px !important;
                    height: 18px !important;
                    display: inline-block;
                    min-height: unset;
                    float: none;
                    padding: 0;
                    margin-left: -15px;
                    margin-right: 15px;
                    vertical-align: text-bottom;

                    &:hover {
                        cursor: pointer;
                    }

                    &:checked {
                        background-color: #D4212F;
                        border-color: #D4212F;
                    }

                    &:focus {
                        outline: unset;
                        border-color: hsl(0, 0%, 80%) !important;
                        box-shadow: none;
                    }
                }
            }

            button {
                padding: 11px 50px;

                &:hover{
                    background-color: #000000;
                    color: white !important;
                }
            }
        }
    }
}

// // src/components/Modal.scss
// .RequestModal {
//     position: relative;
//     z-index: 10;

//     .modal {
//         display: none;
//         position: fixed;
//         z-index: 1;
//         left: 0;
//         top: 0;
//         width: 100%;
//         height: 100%;
//         overflow: auto;
//         background-color: rgb(0, 0, 0);
//         background-color: rgba(0, 0, 0, 0.4);

//         &.show {
//             display: block;
//         }

//         .modal-content {
//             background-color: #fefefe;
//             margin: 15% auto;
//             padding: 40px;
//             border: 1px solid #888;
//             width: 898px;
//         }

//         .close {
//             color: #aaa;
//             font-size: 28px;
//             font-weight: bold;
//             position: absolute;
//             right: 15px;
//             top: 5px;

//             &:hover,
//             &:focus {
//                 color: #000000;
//                 text-decoration: none;
//                 cursor: pointer;
//             }
//         }
//     }

//     form .form-group span.for-error{
//         bottom: -3px;
//     }
// }